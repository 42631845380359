import Link from "next/link";
import { useRouter } from "next/router";
// eslint-disable-next-line import/order
import Head from 'next/head';

import HeaderComponent from "../components/HeaderComponent/HeaderComponent";

const Custom404 = () => {
  const router = useRouter();

  return <>
  <Head>
    <link rel="canonical" href={`${process.env.BASE_URL}/404`} />
  </Head>
  <HeaderComponent />
  <div className="custome_error_page">
    <div className="error_bg">
      <div className="full_errorpage_box">
        <div className="error_img_box">
          <img src="/assets/images/404.png" alt="404 Error" />
          </div>
          <div>
            <p className="text1">Oops! Page Not Found</p>
          </div>
            <p className="text2">This Page doesn&apos;t Exist or was Removed! We suggest you back to home</p>
        
          <div className="text-center" >
            <button className="error_button">
              <Link href="/">
                <a className="error_text">Back to Home</a>
              </Link>
            </button>
          </div>
        </div>
      </div>
  </div>
  </>

}

export default Custom404;